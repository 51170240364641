import AxiosService from '../axios.service'

export default class CartService {

  constructor(props) {
    this.props = props
  }

  async add(dispatch, item) {

    //console.log('service item ADD' , item);
    if (item.qty === 0) {
      return
    }

    try {

      let response = await AxiosService.post(`/checkout/cart/add/${item.parent_id}`, {

        'product_id': item.parent_id,
        'quantity': item.qty,
        'selected_configurable_option': item.id,
        'super_attribute': { [item.color_attr_id]: item.color_code_id, [item.size_attr_id]: item.size_code_id }

      });

      //console.log('response ADD' , response);


      if (response && response.data['success']) {
        this.props?.setOpenSnackCart(true)
        this.props?.setLoadProgress?.(false)

        item.id = response.data['cartId']
        item.additional = response.data['additional']
        item.created_at = response.data['created_at']

        dispatch(
          {
            'cart':
            {
              type: "CHANGE_CART_AMOUNT",
              payload: item,
            }
          }
        )
      }

    } catch (error) {
      //console.log(error);
      this.props?.setLoadProgress?.(false)
      this.props?.setOpenSnackError?.(true)
    }
    this.props?.setLoadProgress?.(false)
  }


  async addMultiple(dispatch, items) {

   

    try {

      let addItems = []
      let parent_id = -1
      items.map((item,index,) => {
        

        if (item.qty > 0) {
          parent_id = item.parent_id
          addItems.push({
            'product_id': item.parent_id,
            'quantity': item.qty,
            'selected_configurable_option': item.id,
            'super_attribute': { [item.color_attr_id]: item.color_code_id, [item.size_attr_id]: item.size_code_id }
          });

        }

      })


      let response = await AxiosService.post(`/checkout/cart/addMultiple/${parent_id}`, addItems);
      
      if (response && response.data['success']) {

        items.map((item,index,) => {
         
          response?.data?.data?.map((responseItem,index,) => {

            if(responseItem?.additional.selected_configurable_option == item.product_id){
              
              item.id = responseItem?.cartId
              item.additional = responseItem?.additional
              item.created_at = responseItem?.created_at

              dispatch(
                {
                  'cart':
                  {
                    type: "CHANGE_CART_AMOUNT",
                    payload: item,
                  }
                }
              )
            }

            

          })
        
          

        
         
        })

        
      }
    
      
      this.props?.setOpenSnackCart(true)
      this.props?.setLoadProgress?.(false)
      
    } catch (error) {
      //console.log(error);
      this.props?.setLoadProgress?.(false)
      this.props?.setOpenSnackError?.(true)
    }
    this.props?.setLoadProgress?.(false)
  }

  async update(dispatch, item, setUpdateQ  = null) {
    //console.log('service item UPDATE' , item);

    if(setUpdateQ){
      setUpdateQ(false)
    }

    try {
      //console.log('items33 => ',)

      let cartId = item.id;//item.child ? item.child.parent_id : item.cartId
      let qty = {};
      qty[cartId] = item.qty

      if (item.qty == 0) {
        await this.remove(dispatch, item);
        return;
      }

      let response = await AxiosService.post(`/checkout/cart`, {
        'qty': qty
      });
      //console.log('response UPDATE' , response);

      if (response && response.data['success']) {
        this.props?.setOpenSnackUpdate(true)
        this.props?.setLoadProgress?.(false)
        dispatch(
          {
            'cart':
            {
              type: "CHANGE_CART_AMOUNT",
              payload: item,
            }
          }
        )

       
      }

      if(setUpdateQ){
        setUpdateQ(true)
      }

    } catch (error) {

      if(setUpdateQ){
        setUpdateQ(false)
      }

      //console.log(error);
      this.props?.setLoadProgress?.(false)
      this.props?.setOpenSnackError?.(true)

    }


  }


  async remove(dispatch, item) {
    //console.log('service item REMOVE' , item);

    try {


      let cartId = item.id;//item.child ? item.child.parent_id : item.cartId
      let qty = {};
      qty[cartId] = item.qty

      let response = await AxiosService.get(`/checkout/cart/remove/${cartId}`)
      //console.log('response REMOVE' , response);
      if (response && response.data['success']) {
        this.props?.setOpenSnackRemove(true)
        this.props?.setLoadProgress?.(false)


        dispatch(
          {
            'cart':
            {
              type: "CHANGE_CART_AMOUNT",
              payload: item,
            }
          }
        )
      }

    } catch (error) {
      this.props?.setLoadProgress?.(false)
      this.props?.setOpenSnackError?.(true)
      //console.log(error);
    }

  }

}